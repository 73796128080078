import { graphql } from 'gatsby';
import { applyPaddingCSS } from '@utils/generatePadding';
import RichText from '@components/RichText/RichText';
import {
  StyledColumnBlock,
  StyledColumnBlockItem,
  StyledColumnBlockContainer,
} from './ColumnBlock.styles';
import { DOMNode, Element } from 'html-react-parser';

const ColumnBlock = ({ elements }: Queries.ColumnBlockFragment) => {
  const { theme__color, column_items, options } = elements;
  const color = theme__color?.value;
  const hasSeparators = options?.value?.includes('has_separators');

  return (
    <StyledColumnBlock css={applyPaddingCSS(elements)}>
      <StyledColumnBlockContainer hasSeparators={hasSeparators} color={color}>
        {column_items.value.map((item, index) => {
          return (
            <StyledColumnBlockItem key={item.system.id}>
              <RichText
                {...item.elements.content}
                customResolveDomNode={(domNode: Element, domToReact: Function) => {
                  // NOTE Custom resolver to handle list of links that are wrapped in <p> tag
                  if (
                    domNode.name === 'p' &&
                    domNode.children.length === 1 &&
                    (domNode.children[0] as Element).name === 'a'
                  ) {
                    return domToReact(domNode.children as DOMNode[], {});
                  }
                }}
              />
            </StyledColumnBlockItem>
          );
        })}
      </StyledColumnBlockContainer>
    </StyledColumnBlock>
  );
};

export const fragment = graphql`
  fragment ColumnBlock on kontent_item_column_block {
    __typename
    elements {
      ...Theme
      ...Padding
      column_items {
        value {
          system {
            id
          }
          ...SharedContent
        }
      }
      options {
        value
      }
    }
  }
`;

export default ColumnBlock;
